import { Fragment } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';


export const MobileMetamaskInfo = (
  
    <Fragment>     
            
        <Box marginTop={2} component={Card} bgcolor={'primary.main'}>
          <CardContent sx={{ maxWidth: 380 }}>
          <Typography
                    variant="h6"
                    gutterBottom
                    color="text.primary"
                    sx={{ color: 'common.white' }}
                  >
                    Setting Up A MetaMask Account
                  </Typography>
        {[
          'Download the MetaMask wallet.',
          'You will need to set up an account.',
          'Make sure to write down and secure your 12-word seed phrase.',
          'This is how you get back into your account if you get locked out or forget your password, as well as migrate the account to a new device.',
        ].map((item, i) => (
          <Grid item xs={12} key={i}>

            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={'#ffb74d'}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText secondaryTypographyProps={{color: 'white',}} secondary={item} />
            </Box>

          </Grid>

        ))}
        </CardContent>
      </Box>

      <Box marginTop={1} component={Card} bgcolor={'primary.main'}>
          <CardContent sx={{ maxWidth: 380 }}>
          <Typography
                    variant="h6"
                    gutterBottom
                    color="text.primary"
                    sx={{ color: 'common.white' }}
                  >
                    Add Funds
                  </Typography>
        {[
          'If you click the three lines in the top left corner of the app interface, you will see the option to add funds. Add Ethereum to your wallet.',
          'MetaMask has a limit of $500 per day to your account.',
          'If you would like to use more Ethereum than you can purchase on MetaMask, you may purchase it on other crypto services such as crypto.com or Trust Wallet and transfer it to your MetaMask wallet.',
          'There will be a transfer fee associated with using this method.'
        ].map((item, i) => (
          <Grid item xs={12} key={i}>

            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={'#ffb74d'}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText secondaryTypographyProps={{color: 'white',}} secondary={item} />
            </Box>

          </Grid>

        ))}
        </CardContent>
      </Box>

      <Box marginTop={1} marginBottom={2} component={Card} bgcolor={'primary.main'}>
          <CardContent sx={{ maxWidth: 380 }}>
          <Typography
                    variant="h6"
                    gutterBottom
                    color="text.primary"
                    sx={{ color: 'common.white' }}
                  >
                     Minting Your NFT
                  </Typography>
        {[
          'Navigate to the browser inside the MetaMask app. It is located at the bottom.',
          'Type in "mint.sorrysamsnft.com" and then connect your MetaMask wallet when prompted.',
          'You may mint more than one NFT at a time. Select the number you would like to mint, and press "Mint."',
          'Once you receive a confirmation of the contract, it should be about 5 minutes before your NFT is visible in OpenSea.',
          ' Congratulations!! You now have your very own SorrySams NFT!'
        ].map((item, i) => (
          <Grid item xs={12} key={i}>

            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={'#ffb74d'}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText secondaryTypographyProps={{color: 'white',}} secondary={item} />
            </Box>

          </Grid>

        ))}
        </CardContent>
      </Box>
 
    </Fragment>
);

export const MobileMintInfo = (
    <Fragment>
        <Box marginTop={2} component={Card} bgcolor={'primary.main'}>
          <CardContent sx={{ maxWidth: 380 }}>
          <Typography
                    variant="h6"
                    gutterBottom
                    color="text.primary"
                    sx={{ color: 'common.white' }}
                  >
                     Minting Your NFT
                  </Typography>
        {[
          'Navigate to the browser inside the MetaMask app. It is located at the bottom.',
          'Type in "mint.niftyapenation.com" and then connect your MetaMask wallet when prompted.',
          'You may mint more than one NFT at a time. Select the number you would like to mint, and press "Mint."',
          'Once you receive a confirmation of the contract, it should be about 5 minutes before your NFT is visible in OpenSea.',
          ' Congratulations!! You now have your very own SorrySams NFT!'
        ].map((item, i) => (
          <Grid item xs={12} key={i}>

            <Box component={ListItem} disableGutters width={'auto'} padding={0}>
              
              <Box
                component={ListItemAvatar}
                minWidth={'auto !important'}
                marginRight={2}
              >
                <Box
                  component={Avatar}
                  bgcolor={'#ffb74d'}
                  width={20}
                  height={20}
                >
                  <svg
                    width={12}
                    height={12}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Box>
              </Box>
              <ListItemText secondaryTypographyProps={{color: 'white',}} secondary={item} />
            </Box>

          </Grid>

        ))}
        </CardContent>
      </Box>
    </Fragment>
);